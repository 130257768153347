import React, { useEffect } from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";
import NoMachines from "./NoMachines";
import { useStore } from "../../../storeManager/stores";
import { observer } from "mobx-react";
import ListItem from "../shared/ListItem";
import PulseLoader from "@mecha/design-components/dist/src/components/Elements/PulseLoader";
import LinkButton from "@mecha/design-components/dist/src/components/Elements/LinkButton";
import {
  AppModule,
  PRE_DEFINED_MACHINE_LIMIT,
} from "../../../config/constants";
import AddMachineModal from "../../Machines/AddMachineModal/AddMachineModal";

const MachineList = () => {
  const { loggedInUser } = useStore().authStore;
  const {
    machinesList,
    setFetchingMachines,
    setMachineList,
    getAllMachines,
    setLimit,
    fetchingMachines,
    addMachineModal,
  } = useStore().machineStore;

  const websocketEvents = async () => {
    const RealTimeEvents = (await import("../../../libs/RealTimeEvents"))
      .default;
    RealTimeEvents.triggerMachineDetailSockets(
      loggedInUser.channel,
      AppModule.MACHINE_LISTING,
    );
  };

  useEffect(() => {
    setLimit(4);
    getAllMachines();
    websocketEvents();
  }, []);

  return (
    <div className="w-full mb-10">
      <div className="w-full flex flex-row justify-between items-center">
        <p className="text-web-h6 dark:text-dark-n-400 text-ligth-n-400 font-medium">
          Machines
        </p>
        <LinkButton
          href="/machines"
          className="__navigate-machines group"
          color="default"
          size="sm"
          onClick={() => {
            setFetchingMachines(true);
            setLimit(PRE_DEFINED_MACHINE_LIMIT);
            setMachineList([]);
          }}
        >
          <span className="flex flex-row items-center gap-2">
            All machines
            <ArrowRightIcon
              height={18}
              width={18}
              className="dark:text-dark-n-200 text-light-n-200 __navigate-machines group-hover:translate-x-1 ease-in-out duration-600"
            />
          </span>
        </LinkButton>
      </div>
      {fetchingMachines ? (
        <div className="mt-4 grid grid-cols-1 gap-5 lg:grid-cols-2 justify-between flex-wrap">
          <PulseLoader className="w-full h-[82px] rounded-md" />
          <PulseLoader className="w-full h-[82px] rounded-md" />
          <PulseLoader className="w-full h-[82px] rounded-md" />
          <PulseLoader className="w-full h-[82px] rounded-md" />
        </div>
      ) : machinesList.length > 0 ? (
        <div className="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2 justify-between flex-wrap">
          {machinesList.map((item, idx) => (
            <ListItem key={idx} data={item} />
          ))}
        </div>
      ) : (
        <NoMachines />
      )}
      {addMachineModal && <AddMachineModal />}
    </div>
  );
};

export default observer(MachineList);
