import React from "react";

const MechaIconSvg = (props: React.ComponentProps<"svg">) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M60 17.1429H36.7971L47.8779 6.06214L41.8157 0L25.7143 16.1036V0H17.1429V23.2029L6.06214 12.1221L0 18.1843L16.1036 34.2857H0V42.8571H23.2029L12.1221 53.9379L18.1821 60L34.2857 43.8964V60H42.8571V36.7971L53.9379 47.8779L60 41.8179L43.8964 25.7143H60V17.1429ZM30 34.2857C29.1524 34.2857 28.3238 34.0344 27.619 33.5634C26.9142 33.0925 26.3649 32.4232 26.0405 31.6401C25.7161 30.857 25.6313 29.9952 25.7966 29.1639C25.962 28.3326 26.3702 27.5689 26.9695 26.9695C27.5689 26.3702 28.3326 25.962 29.1639 25.7966C29.9952 25.6313 30.857 25.7161 31.6401 26.0405C32.4232 26.3649 33.0925 26.9142 33.5634 27.619C34.0344 28.3238 34.2857 29.1524 34.2857 30C34.2857 31.1366 33.8342 32.2267 33.0305 33.0305C32.2267 33.8342 31.1366 34.2857 30 34.2857Z"
      fill="currentColor"
    />
  </svg>
);

export default MechaIconSvg;
