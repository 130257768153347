import React from "react";
import TerminalIcon from "../../assets/svg/TerminalIcon";
import NetworkIcon from "../../assets/svg/NetworkIcon";
import LockAndKeyIcon from "../../assets/svg/LockAndKeyIcon";
import RightPaginationIcon from "../../assets/svg/RightPaginationIcon";
import { LinkButton } from "@mecha/design-components/dist/src/components/Elements/LinkButton";
import MechaMachineIcon from "../../assets/svg/MechaMachineIcon";
import {
  ADD_NEW_MACHINE_DOCS,
  ENABLE_NETWORKING_DOCS,
  SSH_CONNECTIVITY_DOCS,
  DEVELOPER_CLI_DOCS,
} from "../../config/linkConstants";

export const ThingsCard = props => {
  const { title, icon, description, time, link } = props.data;
  return (
    <div className="dark:bg-neutral-150 rounded-lg p-4 flex flex-col bg-custom-950 justify-between">
      <div>
        {icon}
        <h1 className="dark:text-dark-n-400 text-light-n-400 font-semibold text-web-h7  mt-3">
          {title}
        </h1>
        <p className="mt-2 text-web-b4 dark:text-dark-n-300 text-light-n-300">
          {description}
        </p>
      </div>
      <div className="mt-[6px] flex flex-row justify-between items-center">
        <p className="text-web-b5 dark:text-dark-n-500 text-light-n-500 ">
          {time}
        </p>

        <LinkButton
          size="sm"
          href={link}
          color="default"
          className="group flex flex-row items-center gap-2"
        >
          Read more
          <RightPaginationIcon className="" />
        </LinkButton>
      </div>
    </div>
  );
};
const Things = () => {
  const ThingsArr = [
    {
      icon: (
        <MechaMachineIcon className="h-[38px] w-[38px] dark:text-dark-n-400 text-light-n-400 " />
      ),
      title: "Add a Machine",
      description: "Get your machine connected on the Mecha cloud",
      time: "5 min",
      link: {ADD_NEW_MACHINE_DOCS},
    },
    {
      icon: (
        <NetworkIcon className="h-[38px] w-[38px] dark:text-dark-n-400 text-light-n-400 " />
      ),
      title: "Enable Networking",
      description: "Get a Virtual IP, DNS to connect for your machine",
      time: "5 min",
      link: {ENABLE_NETWORKING_DOCS},
    },
    {
      icon: (
        <LockAndKeyIcon className="h-[38px] w-[38px] dark:text-dark-n-400 text-light-n-400 " />
      ),
      title: "SSH from Anywhere",
      description:
        "Once your machine is connected, try SSH from outside its network",
      time: "5 min",
      link: {SSH_CONNECTIVITY_DOCS},
    },
    {
      icon: (
        <TerminalIcon className="h-[38px] w-[38px] dark:text-dark-n-400 text-light-n-400 " />
      ),
      title: "Install Developer CLI",
      description:
        "Install the mecha CLI on your dev machine for faster access",
      time: "5 min",
      link: {DEVELOPER_CLI_DOCS},
    },
  ];
  return (
    <div className="mb-10">
      <h1 className="text-web-h6  dark:text-dark-n-400 text-ligth-n-400 font-medium">
        Get Started
      </h1>
      <div className="mt-5 grid grid-cols-1 md:grid-cols-2  gap-5">
        {ThingsArr.map((thing, index) => (
          <ThingsCard data={thing} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Things;
