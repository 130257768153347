import router from "next/router";
import React from "react";
import dayjs from "dayjs";
import { customToast } from "../../../utils/customToast";
import CopyIcon from "../../../assets/svg/CopyIcon";
import { useStore } from "../../../storeManager/stores";
import MachineStatusIndicator from "../../Machines/TableColumns/MachineStatusIndicator";

const ListItem = ({ data }) => {
  const url = {
    pathname: `/machines/[id]/metrics`,
    query: { id: `${data?.alias}` },
  };
  const machineConnected = !!(
    data?.lastActiveAt && dayjs().diff(data.lastActiveAt, "minutes") <= 2
  );

  const { setMachineInfo, setFetchingmachineData, setMachineCertificate } = useStore().machineStore;
  const { reset: logsReset } = useStore().machineLogsStore;

  const goToMachineDetails = () => {
    router.push(url);
    logsReset();
    setMachineCertificate(null);
    setFetchingmachineData(true);
    setMachineInfo({});
  };
  const onCopy = event => {
    event.stopPropagation();
    navigator.clipboard.writeText(data?.machineId);
    customToast({ msg: "ID copied", toastType: "SUCCESS" });
  };
  return (
    <div
      onClick={goToMachineDetails}
      className="w-full p-4 bg-[#F9F9FA] dark:bg-[#090C13] border border-custom-50 dark:border-[#202431] rounded-md flex flex-row items-center dark:hover:bg-[#15181D] hover:bg-[#EFF4F9] cursor-pointer"
    >
      <div className="flex items-center justify-center relative lg:w-[56px] lg:h-[56px] xs:w-12 xs:h-12 bg-transparent rounded-lg transition ease-in-out border-2 border-custom-50 dark:border-[#202431] hover:scale-95">
        <div className="w-full h-full flex items-center justify-center rounded-lg border-transparent overflow-hidden">
          <img
            src={`${data?.displayIconMediaPath}`}
            alt="machine"
            className="w-full h-full object-cover __machine-image cursor-pointer"
          />
        </div>
        <MachineStatusIndicator
            lastActiveAt={data?.lastActiveAt}
            id={data?.alias}
            key={data?.alias}
          />
      </div>
      <div className="flex flex-col ml-4">
        <p
          onClick={goToMachineDetails}
          className="text-web-h7 dark:text-dark-n-200 text-light-n-200 font-medium"
        >
          {data?.name}
        </p>
        <p
          onClick={onCopy}
          className="md:text-web-b4 text-mob-b4 dark:text-dark-n-400 text-light-n-400 flex flex-row items-center gap-2 group"
        >
          {data?.machineId}
          <CopyIcon className="hidden group-hover:block transition duration-300 ease-in-out" />
        </p>
      </div>
    </div>
  );
};

export default ListItem;
