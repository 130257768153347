import React from "react";

const UrlIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.27273 1.49992H11M11 1.49992V10.2272M11 1.49992L3 9.49992L1 11.4999"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UrlIcon;
