import React from "react";
import UrlIcon from "../../assets/svg/UrlIcon";
import GithubIcon from "../../assets/svg/GitHubIcon";
import DiscordIcon from "../../assets/svg/DiscordIcon";
import MechaIconSvg from "../../assets/svg/MechaIconSvg";
import RedditIcon from "../../assets/svg/RedditIcon";
import { SocialLinks } from "../../config/constants";

const SocialCard = ({ data }) => {
  const { icon, title, link } = data;
  return (
    <a
      href={link}
      target="_blank"
      className="group py-4 px-5 rounded-md dark:bg-dark-760 dark:hover:bg-dark-700 bg-light-760 hover:bg-light-700 transition-all duration-600 ease-in-out flex flex-row justify-between items-center"
      rel="noreferrer"
    >
      <div className="flex flex-row gap-3">
        <p className="dark:text-dark-n-400 text-light-n-400 h-6 w-6"> {icon}</p>
        <h1 className="font-medium text-web-h9 dark:text-dark-n-400 text-light-n-400">
          {title}
        </h1>
      </div>
      <UrlIcon className="dark:text-dark-n-400 text-light-n-400 mt-1 group-hover:-translate-y-1 group-hover:translate-x-1 ease-in-out transition-all duration-600" />
    </a>
  );
};

const { discord, forums, github, reddit } = SocialLinks;

const Socials = () => {
  const SocialsArr = [
    {
      icon: <GithubIcon className="h-6 w-6" />,
      title: "Follow us on GitHub",
      link: github,
    },
    {
      icon: <DiscordIcon className="h-6 w-6" />,
      title: "Join Discord Server",
      link: discord,
    },
    {
      icon: <MechaIconSvg className="h-6 w-6" />,
      title: "Ask a Question",
      link: forums,
    },
    {
      icon: <RedditIcon className="h-6 w-6" />,
      title: "Join r/mecha-org",
      link: reddit,
    },
  ];
  return (
    <div className="flex flex-col gap-4">
      {SocialsArr.map((social, index) => (
        <SocialCard data={social} key={index} />
      ))}
    </div>
  );
};

export default Socials;
