import React from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CopyIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2252_6456)">
      <path
        d="M13.3334 2.50005H3.33337V13.3334"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66663 5.83337H16.6666V15.8334C16.6666 16.7538 15.9204 17.5 15 17.5H8.33329C7.41282 17.5 6.66663 16.7538 6.66663 15.8334V5.83337Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2252_6456">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CopyIcon;
