import React from "react";
import MachineList from "./MachineList";
import WhatsNew from "./WhatsNew";
import ConsoleFooter from "./ConsoleFooter";
import Things from "./Things";

const ConsoleLandingPage = () => {
  return (
    <div className="w-full flex flex-col px-4 md:px-0">
      <div className="w-full gap-5 dark:bg-dark min-h-screen 2xl:container mt-5 md:mt-10 mb-5 2xl:mx-auto flex justify-between lg:flex-nowrap mecha-scroll-1">
        <div className="flex flex-row gap-10 md:gap-20 lg:container mx-auto">
          <div className="w-full">
            <MachineList />
            <Things />
            {/* <BuildApps /> */}
          </div>
          <div className="hidden lg:flex justify-end md:min-w-[440px]">
            <WhatsNew />
          </div>
        </div>
      </div>
      <ConsoleFooter />
    </div>
  );
};

export default ConsoleLandingPage;
