import { MECHA_WEBSITE_LINK } from "../../../config/linkConstants";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
const NewsItem = ({ data }) => {
  dayjs.extend(relativeTime);
  const dateAgo = dayjs(`${data.created_at}`).fromNow(true);
  return (
    <a
      href={`${MECHA_WEBSITE_LINK}/blog/${data.slug}`}
      target="_blank"
      className="flex flex-row"
      rel="noreferrer"
    >
      <div className="h-[72px] aspect-square oveflow-hidden">
        <img
          alt="feature-img"
          src={data.feature_image || "/assets/img/blog-icon.png"}
          className="object-cover h-full rounded-md  "
          // style={{ height: "inherit" }}
        />
      </div>
      <div className="ml-4 flex flex-col justify-between gap-1">
        <p className="text-web-h9  dark:text-dark-n-300 text-light-n-300 font-bold">
          {data?.title.length > 40
            ? `${data?.title.slice(0, 55)}...`
            : data?.title}
        </p>
        {data?.excerpt && (
          <p
            className="text-sm dark:text-dark-n-500 text-light-n-500 font-normal break-words leading-6"
            // dangerouslySetInnerHTML={{ __html: html }}
          >
            {dateAgo} ago
          </p>
        )}
        {/* <div className="flex flex-row items-center gap-[6px]">
          <span className="h-[20px] w-[20px] flex flex-row items-center ">
            {data?.primary_author?.profile_image ? (
              <img
                alt="author-avatar"
                src={data?.primary_author?.profile_image}
                className="h-[20px] w-[20px] rounded-sm"
              />
            ) : (
              <span className="h-[20px] w-[20px] flex items-center justify-center rounded-sm text-white font-bold bg-custom-600 text-xs">
                {data?.primary_author?.name.slice(0, 1).toUpperCase()}
              </span>
            )}
          </span>
          <p className="md:text-web-b5 text-mob-b5 dark:text-dark-n-500 text-light-n-500">
            {data?.primary_author?.name}
          </p>
        </div> */}
      </div>
    </a>
  );
};

export default NewsItem;
