import React, { useState, useEffect } from "react";
import { getMachineActiveStatus } from "../../../utils/services";

const MachineStatusIndicator = ({ lastActiveAt, id }) => {
  const [isActive, setIsActive] = useState(getMachineActiveStatus(lastActiveAt));

  useEffect(() => {
    // Update the active status immediately when lastActiveAt changes
    const updateStatus = () => {
      const active = getMachineActiveStatus(lastActiveAt);
      setIsActive(active);
    };

    updateStatus(); // Initial status update

    const interval = setInterval(() => {
      updateStatus();
    }, 2 * 60 * 1000); // Re-check status every 2 minutes

    return () => clearInterval(interval);
  }, [lastActiveAt]);

  const indicatorClassName = isActive
    ? "__machine-online-"
    : "__machine-offline-";
  const indicatorColor = isActive
    ? "bg-leaf-500 shadow-leaf-500 shadow-c-100"
    : "bg-store-gray";

  return (
    <div>
      <p
        className={`
          w-4 h-4 rounded-full absolute bottom-0 right-0 translate-y-1 translate-x-1 
          border-[3px] dark:border-[#090C13] border-[#F9F9FA]
          ${indicatorColor} ${indicatorClassName}${id}`}
      />
    </div>
  );
};

export default MachineStatusIndicator;
