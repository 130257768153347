import React from "react";
import { Button } from "@mecha/design-components/dist/src/components/Elements/Button";
import { useRouter } from "next/router";
import { useStore } from "../../../storeManager/stores";
import AddMachineModal from "../../Machines/AddMachineModal/AddMachineModal";
import { observer } from "mobx-react";
import { PlusIcon } from "@heroicons/react/solid";

const NoMachines = () => {
  const { setAddMachineModal } = useStore().machineStore;
  return (
    <div className="p-10 dark:bg-[#090C13] bg-[#F9F9FA] h-max w-full mt-4 border dark:border-[#202431] rounded-lg flex flex-col justify-center items-center">
      <div className="w-[104px] h-[82px]">
        <img
          alt="no-machines-img"
          src="/assets/img/no-machine-image.png"
          className="object-cover h-full"
        />
      </div>

      <p className="text-sm text-custom-410 dark:text-custom-70 mt-2 text-center">
        No machines found under your account.
      </p>
      <Button
        size="m"
        onClick={() => {
          setAddMachineModal(true);
        }}
        color="primary"
        className="mt-4"
      >
        <span className="flex flex-row gap-1 items-center">
          <PlusIcon className="w-3 h-3" />
          New machine
        </span>
      </Button>
    </div>
  );
};

export default observer(NoMachines);
