import React from "react";

const LockAndKeyIcon = props => {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.3403 33.4502H8.41643C6.66752 33.4502 5.24976 32.0325 5.24976 30.2836V15.9111H25.8331M10.117 15.9111V11.1276C10.117 7.60523 13.0226 4.74976 16.6068 4.74976C20.1909 4.74976 23.0965 7.60523 23.0965 11.1276V15.8331"
        stroke="currentColor"
        strokeWidth="3.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0137 33.5421V31.2565M30.0137 31.2565H26.8104M30.0137 31.2565V27.4671M30.0137 22.3794C29.466 22.383 28.9231 22.2805 28.416 22.0778C27.909 21.8751 27.4479 21.5763 27.0594 21.1985C26.6708 20.8207 26.3624 20.3714 26.152 19.8764C25.9416 19.3815 25.8333 18.8507 25.8333 18.3146C25.8333 17.7785 25.9416 17.2477 26.152 16.7528C26.3624 16.2578 26.6708 15.8085 27.0594 15.4307C27.4479 15.0529 27.909 14.7541 28.416 14.5514C28.9231 14.3487 29.466 14.2463 30.0137 14.2498C31.1024 14.2684 32.1401 14.7047 32.9033 15.4649C33.6665 16.2251 34.0941 17.2483 34.0941 18.3141C34.0941 19.3799 33.6665 20.4031 32.9033 21.1633C32.1401 21.9235 31.1024 22.3608 30.0137 22.3794ZM30.0137 22.3794V27.4671M30.0137 27.4671H27.6239"
        stroke="currentColor"
        strokeWidth="3.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LockAndKeyIcon;
