import React from "react";

const GithubIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3986 0C5.54242 0 0 5.50022 0 12.3042C0 17.7433 3.55125 22.3472 8.47784 23.9769C9.09367 24.0991 9.31947 23.7121 9.31947 23.3861C9.31947 23.1009 9.29894 22.1231 9.29894 21.1046C5.85033 21.8379 5.13187 19.6378 5.13187 19.6378C4.57763 18.2119 3.75653 17.8452 3.75653 17.8452C2.62752 17.0914 3.83864 17.0914 3.83864 17.0914C5.09081 17.1729 5.74769 18.3544 5.74769 18.3544C6.85617 20.2286 8.64206 19.6989 9.36053 19.373C9.46316 18.5785 9.7916 18.0285 10.1406 17.7229C7.38989 17.4377 4.49552 16.3784 4.49552 11.6523C4.49552 10.3078 4.98817 9.20778 5.76822 8.35219C5.64505 8.04662 5.21398 6.78361 5.89138 5.0928C5.89138 5.0928 6.93828 4.76686 9.29894 6.35581C10.2843 6.09099 11.3517 5.94839 12.3986 5.94839C13.4455 5.94839 14.5129 6.09099 15.4982 6.35581C17.8589 4.76686 18.9058 5.0928 18.9058 5.0928C19.5832 6.78361 19.1521 8.04662 19.029 8.35219C19.8295 9.20778 20.3017 10.3078 20.3017 11.6523C20.3017 16.3784 17.4073 17.4174 14.6361 17.7229C15.0877 18.11 15.4777 18.8434 15.4777 20.0045C15.4777 21.6546 15.4572 22.9787 15.4572 23.3861C15.4572 23.7121 15.683 24.0991 16.2988 23.9769C21.2254 22.3472 24.7767 17.7433 24.7767 12.3042C24.7972 5.50022 19.2342 0 12.3986 0Z"
      fill="currentColor"
    />
  </svg>
);

export default GithubIcon;
