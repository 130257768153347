import Link from "next/link";
import {
  MECHA_WEBSITE_LINK,
  MECHA_FORUMS,
  MECHA_DOCS_URL,
  MECHA_PRIVACY_DOCS,
  MECHA_TERMS_DOCS,
} from "../../config/linkConstants";
import StatusBadge from "../Layout/StatusBadge";

const ConsoleFooter = () => {
  return (
    <div className="mt-12 mb-20 md:h-24 footer ">
      <div className="w-full md:h-24 flex flex-col-reverse space-y-5 md:space-y-0 md:flex-row items-start md:items-center justify-start md:justify-between border-t dark:border-neutral-150 text-base ">
        <div className="w-full mt-5 md:mt-0 md:w-7/12 flex flex-col md:flex-row items-start md:items-center justify-start space-y-5 md:space-y-0 md:space-x-10 dark:text-custom-300">
          <Link
            target="_blank"
            href={MECHA_WEBSITE_LINK}
            className="__home-link"
          >
            Home
          </Link>
          <Link target="_blank" href={MECHA_FORUMS} className="__forum-link">
            Forum
          </Link>
          <Link target="_blank" href={MECHA_DOCS_URL} className="__forum-docs">
            Docs
          </Link>
          <Link target="_blank" href={MECHA_PRIVACY_DOCS} className="__forum-privacy">
            Privacy
          </Link>
          <Link target="_blank" href={MECHA_TERMS_DOCS} className="__forum-terms">
            Terms
          </Link>
        </div>
        <div className="my-5 md:my-0">
          <StatusBadge />
        </div>
      </div>
    </div>
  );
};

export default ConsoleFooter;
