import React, { useEffect, useState } from "react";
import UrlIcon from "../../assets/svg/UrlIcon";
import NewsItem from "./WhatsNewItems/NewsItem";
import Logger from "../../libs/Logger";
import { ErrorReporter } from "../../libs/ErrorReporter";
import { Link } from "@mecha/design-components/dist/src/components/Elements/Link";
import { getBlogPosts } from "../../api/blogPostsApis";
// import Loader from "../../../public/assets/svg/LoaderIcon";
import { MECHA_WEBSITE_LINK } from "../../config/linkConstants";
import Socials from "./Socials";
import PulseLoader from "@mecha/design-components/dist/src/components/Elements/PulseLoader";
import LinkButton from "@mecha/design-components/dist/src/components/Elements/LinkButton";
import RightPaginationIcon from "../../assets/svg/RightPaginationIcon";
const WhatsNew = () => {
  const log = new Logger({ module: "Console", component: "Blogs" });
  const [featuredBlogPost, setFeaturedBlogPost] = useState([]);
  const [blogPost, setBlogPost] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchBlogPosts = async () => {
    setLoading(true);
    const res = await getBlogPosts(
      "order=published_at%20DESC&filter=featured:false&limit=3&include=tags,authors",
    ).catch(error => {
      log.error({ error: error }, "(blogs) : error fetching blogs from ghost");
      ErrorReporter.collect(error, "Error in fetching Blogs from Ghost.");
    });
    setBlogPost(res?.posts || []);
    setLoading(false);
  };
  const fetchFeaturedBlogPosts = async () => {
    setLoading(true);
    const res = await getBlogPosts(
      "&order=published_at%20DESC&filter=featured:true&limit=1&include=tags,authors",
    ).catch(error => {
      log.error({ error: error }, "(blogs) : error fetching blogs from ghost");
      ErrorReporter.collect(error, "Error in fetching Blogs from Ghost.");
    });
    setFeaturedBlogPost(res?.posts || []);
    setLoading(false);
  };
  useEffect(() => {
    fetchBlogPosts();
    fetchFeaturedBlogPosts();
  }, []);
  const feature_image = featuredBlogPost[0]?.feature_image;
  const title = featuredBlogPost[0]?.title;
  const excerpt = featuredBlogPost[0]?.excerpt;
  const slug = featuredBlogPost[0]?.slug;
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="dark:bg-dark-870 bg-light-870 h-max max-w-[440px] p-5  border dark:border-[#202431] rounded-lg flex flex-col gap-1">
        {/* Title */}
        <div className="flex flex-row justify-between items-center">
          <p className="text-web-h7  font-medium dark:text-dark-n-400 text-light-n-400">
            What&apos;s new
          </p>
          <Link
            color="primary"
            target="_blank"
            className="flex items-center flex-row dark:text-dark-n-200 text-light-n-200"
            href={`${MECHA_WEBSITE_LINK}/blog`}
          >
            <div className="flex flex-row items-center gap-2 group">
              Blog
              <UrlIcon className="dark:text-dark-n-200 text-light-n-200 group-hover:-translate-y-1 group-hover:translate-x-1 transition duration-300 ease-in-out" />
            </div>
          </Link>
        </div>
        {/* Banner */}
        <div>
          {!loading ? (
            <a
              target="_blank"
              href={`${MECHA_WEBSITE_LINK}/blog/${slug}`}
              className="flex flex-col item-center justify-center mt-5 rounded-lg"
              rel="noreferrer"
            >
              {/* <div className="flex-1"> */}
              {feature_image && (
                <img
                  alt="featured-blog"
                  src={feature_image}
                  className="object-cover rounded-lg "
                />
              )}
              <div>
                {title && (
                  <p className="font-bold dark:text-dark-n-300 text-light-n-300 md:text-web-h8 text-mob-h8 mt-4">
                    {title?.length > 28 ? title.slice(0, 28) + "..." : title}
                  </p>
                )}
                {excerpt && (
                  <div className="flex flex-col gap-2">
                    <p
                      className="md:text-web-b4 text-mob-b4 dark:text-dark-n-400 text-light-n-400 font-normal text-wrap mt-2 leading-6"
                      // dangerouslySetInnerHTML={{ __html: html }}
                    >
                      {excerpt}
                    </p>
                    <LinkButton
                      size="sm"
                      target="_blank"
                      href={`${MECHA_WEBSITE_LINK}/blog/${slug}`}
                      color="default"
                      className="group flex flex-row items-center gap-2"
                    >
                      Read more
                      <RightPaginationIcon className="" />
                    </LinkButton>
                  </div>
                )}
              </div>
            </a>
          ) : (
            <div className="flex flex-col w-full">
              {/* <Loader className="w-10 h-10 text-primary-500 inline mr-3 animate-spin" /> */}
              <PulseLoader className="w-full h-[250px] rounded-lg  my-2" />
              <PulseLoader className="h-7 rounded-lg w-full" />
              <PulseLoader className="rounded-lg w-full h-[48px]  mt-2" />
            </div>
          )}
        </div>
        {/* )} */}
        {/* News List */}
        {!loading && blogPost ? (
          <div className="mt-5 flex flex-col gap-5">
            {blogPost.map((value, idx) => {
              return <NewsItem data={value} key={idx} />;
            })}
          </div>
        ) : (
          <div className="mt-5 flex flex-col gap-5">
            <PulseLoader className="w-full rounded-lg h-[72px]" />
            <PulseLoader className="w-full rounded-lg h-[72px]" />
          </div>
        )}
      </div>
      <Socials />
    </div>
  );
};

export default WhatsNew;
