import React, { useState, useEffect } from "react";
import { INSTATUS_URL } from "../../config/linkConstants";
import { cn } from "../../utils/tailwindHelper";

const StatusBadge = () => {
  const statusObj = {
    UP: "All systems operational",
    HASISSUES: "System outage",
    UNDERMAINTENANCE: "Under maintenance",
  };

  const [statusData, setStatusData] = useState({
    name: "",
    url: INSTATUS_URL,
    status: "",
  });

  useEffect(() => {
    const getStatus = async () => {
      try {
        const resp = await fetch(`${INSTATUS_URL}/summary.json`);
        const data = await resp.json();
        const { page: statusData } = data; // Destructure directly
        setStatusData(statusData);
      } catch (error) {
        console.error("Error fetching status:", error);
      }
    };
    getStatus();
  }, []);

  const statusClass = {
    UP: "bg-custom-1060",
    UNDERMAINTENANCE: "bg-custom-1070",
    HASISSUES: "bg-custom-1090",
  };

  return (
    <div>
      {statusData.status && (
        <a
          href={statusData.url}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-80 w-max py-1 px-2 rounded-md dark:bg-custom-40 flex items-center gap-2 bg-[#EFF2F9] text-sm md:text-base dark:text-custom-50 text-custom-410"
        >
          <div
            className={cn(
              "h-[12px] w-[12px] rounded-full",
              statusClass[statusData.status],
            )}
          ></div>
          <p>{statusObj[statusData.status]}</p>
        </a>
      )}
    </div>
  );
};

export default StatusBadge;
