import React from "react";

const TerminalIcon = (props: React.ComponentProps<"svg">) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.66667 9.50033L8.16667 7.00033L5.66667 4.50033M9.83333 9.50033H13.1667M1.5 1.16699H16.5V11.167C16.5 12.0875 15.7538 12.8337 14.8333 12.8337H3.16667C2.24619 12.8337 1.5 12.0875 1.5 11.167V1.16699Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TerminalIcon;
